import { dehydrate, QueryClient } from '@tanstack/react-query'
import { ChainId, V3_SUBGRAPHS } from '@pancakeswap/chains'
import BigNumber from 'bignumber.js'
import request, { gql } from 'graphql-request'
import { GetStaticProps } from 'next'
import Home from '../views/Home'

const IndexPage = () => {
  return <Home />
}

IndexPage.snowEffect = false

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
    revalidate: 60 * 60 * 24 * 30, // 30 days
  }
}

IndexPage.chains = []

export default IndexPage
