import { useTranslation } from '@pancakeswap/localization'
import { styled } from 'styled-components'
import { Box, Flex, useMatchBreakpoints } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'
import CountUp from 'react-countup'
import request, { gql } from 'graphql-request'
import { ChainId, V3_SUBGRAPHS } from '@pancakeswap/chains'
import BigNumber from 'bignumber.js'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const title = `https://raw.githubusercontent.com/hiveswap/assets/main/hiveswap-logo.svg`
const titleDark = `https://raw.githubusercontent.com/hiveswap/assets/main/hiveswap-logo-dark.svg`
const bg1 = `https://raw.githubusercontent.com/hiveswap/assets/main/home-bg-1.png`
const bg2 = `https://raw.githubusercontent.com/hiveswap/assets/main/home-bg-2.png`

const ResponsiveTwoColumnsWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 940px;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 30px;
  margin-bottom: 30px;
  text-align: center;
`

const ItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  margin-bottom: 10px;
`

const ItemValue = styled.div`
  background: ${({ theme }) => theme.colors.input};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  font-size: 20px;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-weight: 600;
  padding: 4px 10px;
`

const ResponsiveTwoColumns = styled(Box)`
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;

  grid-template-rows: max-content;
  grid-auto-flow: row;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 1fr;
  }
`

const Card = styled.div`
  border-radius: 20px;
  padding: 30px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
`

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${bg1}), url(${bg2});
  background-size: 260px 200px, 200px 160px;
  background-repeat: no-repeat;
  background-position: right top, left bottom;
  padding: 30px;
  min-height: calc(100vh - 58px);
`

const WrapperBG = styled(Box)`
  background: ${({ theme }) => theme.colors.gradientBubblegum};
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const router = useRouter()
  const { theme } = useTheme()
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()

  useEffect(() => {
    router.push('/swap')
  }, [])

  const query = gql`
    query ExchangeInfo {
      factories {
        totalVolumeUSD
        txCount
        totalVolumeETH
        totalValueLockedUSD
        totalValueLockedETH
        totalValueLockedETHUntracked
        totalValueLockedUSDUntracked
        untrackedVolumeUSD
        totalProtocolFeesUSD
        totalProtocolFeesETH
        totalFeesUSD
        totalFeesETH
        poolCount
        txCount
      }
      tokens {
        id
      }
      pancakeDayDatas(first: 2, orderBy: date, orderDirection: desc) {
        volumeETH
        volumeUSD
        txCount
        date
        tvlUSD
        feesUSD
        volumeUSDUntracked
      }
    }
  `
  const [tvl, setTvl] = useState(0)
  const [tvl24h, setTvl24h] = useState(0)
  const [txCount, setTxCount] = useState(0)
  const [txCount24h, setTxCount24h] = useState(0)
  const [volume24h, setVolume24h] = useState(0)
  const [poolCount, setPoolCount] = useState(0)
  const [totalVolume, setTotalVolume] = useState(0)
  const [coinCount, setCoinCount] = useState(0)

  useEffect(() => {
    const q = async () => {
      const res = await request(V3_SUBGRAPHS[ChainId.MAP], query)
      const day1 = res?.pancakeDayDatas?.[1]
      const day2 = res?.pancakeDayDatas?.[0]

      const tvl24hT = new BigNumber(day2?.tvlUSD).minus(day1?.tvlUSD).toNumber()
      const volume24hT = new BigNumber(day2?.volumeUSD).minus(day1?.volumeUSD).toNumber()

      setTvl(res?.factories?.[0]?.totalValueLockedUSD)
      setTvl24h(tvl24hT < 0 ? 0 : tvl24hT)
      setTxCount(res?.factories?.[0]?.txCount)
      setTxCount24h(new BigNumber(day2?.txCount).minus(day1?.txCount).toNumber())
      setVolume24h(res?.pancakeDayDatas?.[0].volumeUSD)
      setTotalVolume(res?.factories?.[0]?.totalVolumeUSD)
      setPoolCount(res?.factories?.[0]?.poolCount)
      setCoinCount(res?.tokens?.length)
    }
    q()
  }, [])

  // const txCount24h = new BigNumber(day2?.txCount).minus(day1?.txCount).toNumber()
  // const tvl24h = new BigNumber(day2?.tvlUSD).minus(day1?.tvlUSD).toNumber()
  // const volume24h = new BigNumber(day2?.volumeUSD).minus(day1?.volumeUSD).toNumber()

  return <></>

  return (
    <Box style={{ width: isMobile ? '100vw' : 'calc(100vw - 240px)', overflow: 'hidden', boxSizing: 'border-box' }}>
      <WrapperBG>
        <Wrapper>
          <Box>
            <Flex justifyContent="center" mb="20px">
              {theme?.isDark ? (
                <img src={titleDark} alt="title" width={200} height={36} />
              ) : (
                <img src={title} alt="title" width={230} height={36} />
              )}
            </Flex>
            <Subtitle>Used by millions.Trusted with billions.</Subtitle>
            <ResponsiveTwoColumnsWrapper>
              <ResponsiveTwoColumns>
                <Card>
                  <ResponsiveTwoColumns>
                    <div>
                      <ItemTitle>{t('Total Volume')}</ItemTitle>
                      <ItemValue>
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={totalVolume}
                          decimalPlaces={3}
                          decimals={3}
                          duration={1}
                          prefix="$"
                          separator=","
                        >
                          {({ countUpRef }) => <span ref={countUpRef} />}
                        </CountUp>
                      </ItemValue>
                    </div>
                  </ResponsiveTwoColumns>
                  <ResponsiveTwoColumns mt="30px">
                    <div>
                      <ItemTitle>{t('TVL')}</ItemTitle>
                      <ItemValue>
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={tvl}
                          decimalPlaces={3}
                          decimals={3}
                          duration={1}
                          prefix="$"
                          separator=","
                        >
                          {({ countUpRef }) => <span ref={countUpRef} />}
                        </CountUp>
                      </ItemValue>
                    </div>
                  </ResponsiveTwoColumns>
                </Card>
                <Card>
                  <ResponsiveTwoColumns>
                    <div>
                      <ItemTitle>
                        {t('Total')}&nbsp;
                        {t('Transactions')}
                      </ItemTitle>
                      <ItemValue>
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={txCount}
                          decimalPlaces={3}
                          decimals={0}
                          duration={1}
                          prefix=""
                          separator=","
                        >
                          {({ countUpRef }) => <span ref={countUpRef} />}
                        </CountUp>
                      </ItemValue>
                    </div>
                  </ResponsiveTwoColumns>
                  <ResponsiveTwoColumns mt="30px">
                    <div>
                      <ItemTitle>{t('Pairs')}</ItemTitle>
                      <ItemValue>
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={poolCount}
                          decimalPlaces={3}
                          decimals={0}
                          duration={1}
                          prefix=""
                          separator=","
                        >
                          {({ countUpRef }) => <span ref={countUpRef} />}
                        </CountUp>
                      </ItemValue>
                    </div>
                    <div>
                      <ItemTitle>{t('Tokens')}</ItemTitle>
                      <ItemValue>
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={coinCount}
                          decimalPlaces={3}
                          decimals={0}
                          duration={1}
                          prefix=""
                          separator=","
                        >
                          {({ countUpRef }) => <span ref={countUpRef} />}
                        </CountUp>
                      </ItemValue>
                    </div>
                  </ResponsiveTwoColumns>
                </Card>
              </ResponsiveTwoColumns>
            </ResponsiveTwoColumnsWrapper>
          </Box>
        </Wrapper>
      </WrapperBG>
    </Box>
  )
}

export default Home
